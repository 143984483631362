import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  label?: string
  list: ItemProps[]
  title?: string
}

export const Directions = memo(function Directions({
  label,
  list,
  title,
}: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <List>
        {list.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin: 15rem auto 12.5rem;
  padding: 0 17.083vw;

  @media (max-width: 1023px) {
    margin: 7.5rem auto;
    padding: 0 6.039vw;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  margin-bottom: 0.375rem;
  text-align: center;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const List = styled.div`
  margin-top: 3.125rem;

  @media (max-width: 1023px) {
    margin-top: 3rem;
  }
`
