import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Car, ChevronDown, Plane, Train } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo, useState } from 'react'
import Collapsible from 'react-collapsible'

export interface Props {
  description?: string
  icon: string
  label: string
}

export const Item = memo(function Item({ description, icon, label }: Props) {
  if (!icon && !label) {
    return null
  }

  const [open, setOpen] = useState(false)

  const SVG = () => {
    switch (icon) {
      case 'plane':
        return <Plane />
      case 'train':
        return <Train />
      default:
        return <Car />
    }
  }

  const trigger = (
    <Title dial={4} row>
      {SVG()}
      {label}
      <Button className="button" dial={5}>
        <ChevronDown />
      </Button>
    </Title>
  )

  return (
    <FadeInUp>
      <Container className={open ? 'open' : undefined}>
        <Collapsible
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          transitionTime={200}
          trigger={trigger}
        >
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
        </Collapsible>
      </Container>
    </FadeInUp>
  )
})

const Container = styled.div`
  border: 6px solid ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 1.875rem;
  margin-top: 0.75rem;
  transition: 0.15s ease-in-out;
  &.open {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    .button {
      svg {
        transform: scaleY(-1) translateY(0.0625rem);
      }
    }
  }
`

const Title = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 1.5rem 1.5rem 1.5rem 1.875rem;
  text-transform: uppercase;
  &:hover {
    .button {
      background: ${({ theme }) => theme.colors.variants.primaryLight1};
      svg {
        stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
      }
    }
  }

  > svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin-right: 1rem;
  }

  @media (max-width: 1023px) {
    padding: 1.125rem 1.125rem 1.125rem 1.5rem;
  }
`

const Button = styled(FlexBox)`
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  margin-left: auto;
  transition: 0.15s ease-in-out;

  svg {
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight1};
    stroke-width: 3;
    transition: stroke 0.15s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: 2.25rem;
    height: 2.25rem;
    svg {
      width: auto;
      height: 1rem;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin: 0 5.25rem 2.125rem 4.75rem;

  @media (max-width: 1023px) {
    margin: 0 6.039vw 1.5rem;
  }
`
